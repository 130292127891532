<template>
  <div>
    <div>
      <MenuThird :listName="'shopSetting'"></MenuThird>
    </div>
    <div class="menu-third">
      <router-view />
    </div>
  </div>
</template>

<script>
import MenuThird from "@/components/menu/menu-third.vue";
export default {
  components: { MenuThird },

  directives: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.menu-third {
  margin-top: 20px;
}
</style>
